import React from 'react';

import List from './list/list.component';
import PickRandomItem from './pick-random-item/pick-random-item.component';

const App = () => {
  return (
    <div className='container mt-5'>
      <List />
      <PickRandomItem />
    </div>
  );
};

export default App;
