import React, { useState } from 'react';
import { connect } from 'react-redux';

const PickRandomItem = ({ list }) => {
  const [pickedItem, setPickedItem] = useState(null);

  const pickRandomItem = () => {
    const randomItem = list[Math.floor(Math.random() * list.length)];

    setPickedItem(randomItem);
  };

  return (
    <div className='bg-ligt p-4 mt-5 shadow rounded' onClick={pickRandomItem}>
      <button className='btn btn-info'>Random item kiezen</button>

      {pickedItem && (
        <div className='mt-4'>
          <h2 className='ml-3'>Uitgekozen item:</h2>
          <div className='py-2 px-3 shadow-sm rounded mb-3'>
            <h4>{pickedItem.content}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: state.list,
});

export default connect(mapStateToProps)(PickRandomItem);
